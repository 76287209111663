.str-chat.str-chat-channel.messaging {
  background: #fafafa;
  height: 100%;
}

.str-chat__channel-list-team__main {
  min-width: 230px;
  width: 100%;
  background: #EDEEE9;
}

.str-chat.str-chat-channel-list.messaging {
  background: #ffffff;
  width: 100%;
}

.dark.str-chat.str-chat-channel-list.messaging {
  background: #212326 !important;
}

.str-chat-channel.messaging .str-chat__main-panel {
  padding: 0 !important;
}

.messaging.str-chat .str-chat__list .str-chat__reverse-infinite-scroll {
  padding-top: 0;
}

.messaging.str-chat.dark .str-chat__list {
  padding: 0 30px 0;
  background: #282a2d;
}

.str-chat-channel.messaging .str-chat__main-panel {
  padding: 30px 30px 0 0;
}

.str-chat-channel.messaging .str-chat__main-panel:not(:last-child) {
  padding: 20px 0 0 0;
}

.str-chat-channel.messaging .str-chat__main-panel:not(:last-child) .messaging__channel-header {
  border-radius: 10px 0 0 0;
}

.str-chat__message-simple-status {
  display: none;
}

.messaging.str-chat.dark .str-chat__message--system__date {
  color: rgba(255, 255, 255, 0.7);
}

.messaging.str-chat.dark .str-chat__message--system__text p {
  color: rgba(255, 255, 255, 0.7);
}

.messaging.str-chat.dark .str-chat__message--system__line {
  background: rgba(255, 255, 255, 0.1);
}

.str-chat__message--system {
  padding: 20px;
}

.image-gallery-slides {
  width: 80vw;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-gallery-slide {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.str-chat-channel .str-chat__container .rfu-dropzone {
  width: 92%;
}

/* Mobile View */
@media screen and (max-width: 640px) {
  .str-chat-channel-list.messaging {
    float: unset;
  }

  #mobile-channel-list {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    transform: translate3d(-100vw, 0, 0);
    transition: transform .3s cubic-bezier(0, .52, 0, 1);
  }

  #mobile-channel-list.show {
    transform: translate3d(0vw, 0, 0);
    z-index: 1000;
  }
}

/* To fix inherited styles */
@media screen and (max-width: 960px) {
  .str-chat-channel-list.messaging {
    position: unset;
    left: unset;
    top: unset;
    z-index: unset;
    min-height: unset;
    overflow-y: unset;
    box-shadow: unset;
    transition: unset;
  }
}
